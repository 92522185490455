<template>
  <div>
    <el-button
      type="success"
      size="mini"
      style="width: 100%"
      @click.prevent="addServer"
      class="add-server-warp"
      >添加设备服务</el-button
    >
    <el-skeleton
      :style="{
        width: '100%',
        overflow: 'auto',
        maxHeight: '509px',
        minHeight: '200px'
      }"
      :loading="loading"
      animated
      class="infinite-list-wrapper"
    >
      <el-descriptions
        class="margin-top"
        :column="2"
        size="mini"
        border
        :labelStyle="{ width: '100px', textAlign: 'center' }"
        v-for="(propItem, propkey) in deviceList"
        :key="propItem.nextId"
      >
        <template slot="extra">
          <el-button
            type="primary"
            size="small"
            @click.prevent="saveServer(propkey)"
            :loading="propItem.butLoading"
            v-show="propItem.isAdd || propItem.isEdit"
            >保存</el-button
          >
          <el-button
            type="warning"
            size="small"
            @click.prevent="editServer(propkey, propItem.data.id, true)"
            :loading="propItem.butLoading"
            v-show="!propItem.isEdit"
            >编辑</el-button
          >
        </template>
        <el-descriptions-item>
          <template slot="label" style="width: 50px">
            <i class="el-icon-user"></i>
            服务名称
          </template>
          <el-select
            v-model="propItem.data.serveId"
            placeholder="请选择服务"
            style="width: 100%"
            class="filter-item"
            clearable
            v-if="propItem.isAdd"
          >
            <el-option
              v-for="item in serverOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <span v-else>{{ propItem.data.name }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-set-up"></i>
            状态
          </template>
          <el-select
            v-model="propItem.data.status"
            placeholder="请选择状态"
            style="width: 100%"
            class="filter-item"
            clearable
            v-if="propItem.isEdit || propItem.isAdd"
          >
            <el-option
              v-for="item in SERVER_STATUS"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
          <span v-else>
            {{ getValue(SERVER_STATUS, propItem.data.status) }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
    </el-skeleton>
  </div>
</template>

<script>
import {
  getServeList,
  getDeviceServeList,
  addServe,
  updateServeStatus
} from "@/api/equipment";
import { getValue, SERVER_STATUS } from "@/utils/enumUtile";

export default {
  computed: {
    SERVER_STATUS() {
      return SERVER_STATUS;
    }
  },
  props: {
    deviceInfoRef: Object
  },
  watch: {
    deviceInfoRef(newVal) {
      this.deviceInfo = newVal;
      this.getList();
      this.getServerOption();
    }
  },
  components: {},
  data() {
    return {
      deviceInfo: this.deviceInfoRef,
      deviceList: [],
      loading: false,
      serverOption: []
    };
  },
  created() {
    this.getList();
    this.getServerOption();
  },
  methods: {
    getValue,
    getList() {
      const deviceInfo = this.deviceInfo;
      if (!deviceInfo || Object.keys(deviceInfo).length === 0) {
        return;
      }
      this.loading = true;
      const params = {
        devicesId: deviceInfo.id
      };
      getServeList(params)
        .then(resp => {
          const optionData = [];
          const data = resp.data;
          if (data && data.length !== 0) {
            data.map((item, key) => {
              optionData.push({
                nextId: key,
                isAdd: false,
                isEdit: false,
                butLoading: false,
                data: {
                  id: item.id || 0,
                  name: item.serve.name,
                  status: item.status,
                  serveId: item.serve_id,
                  deviceId: item.devices_id
                }
              });
            });
          }
          this.deviceList = optionData;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 查询服务
     */
    getServerOption() {
      const deviceInfo = this.deviceInfo;
      const params = {
        page: 1,
        limit: 9999,
        typeId: deviceInfo.type_id
      };
      getDeviceServeList(params).then(resp => {
        this.serverOption = resp.data.data;
      });
    },
    /**
     * 添加服务
     */
    addServer() {
      const deviceInfo = this.deviceInfo;
      const addItem = {
        data: {
          id: 0,
          deviceId: deviceInfo.id,
          serveId: "",
          name: "",
          status: ""
        },
        nextId: 2,
        isAdd: true,
        isEdit: false,
        butLoading: false
      };
      this.deviceList.unshift(addItem);
    },
    /**
     * 编辑 prop item
     * @param propIndex
     * @param id 要修改的编号
     * @param status
     */
    editServer(propIndex, id, status) {
      this.deviceList[propIndex].isEdit = status;
      this.deviceList[propIndex].data.id = id;
    },
    /**
     * 添加服务
     * @param propKey
     */
    saveServer(propKey) {
      this.deviceList[propKey].butLoading = true;
      const serverItem = this.deviceList[propKey];
      const data = serverItem.data;

      if (data.id === 0) {
        const params = {
          id: data.deviceId,
          serveId: data.serveId,
          status: data.status
        };
        addServe(params)
          .then(resp => {
            this.deviceList[propKey].butLoading = false;
            if (resp.code === 0) {
              this.$notify({
                title: "成功",
                message: resp.data,
                type: "success",
                duration: 2000
              });
            } else {
              this.$notify({
                title: "提示",
                message: resp.data,
                type: "warning",
                duration: 2000
              });
            }
          })
          .finally(() => {
            this.editServer(propKey, false);
            this.getList();
          });
      } else {
        const params = {
          id: data.id,
          status: data.status
        };
        updateServeStatus(params)
          .then(resp => {
            if (resp.code === 0) {
              this.$notify({
                title: "成功",
                message: resp.data,
                type: "success",
                duration: 2000
              });
            } else {
              this.$notify({
                title: "提示",
                message: resp.data,
                type: "warning",
                duration: 2000
              });
            }
          })
          .finally(() => {
            this.editServer(propKey, false);
            this.getList();
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.add-server-warp {
  margin-bottom: 20px;
}
.margin-top {
  margin-bottom: 10px;
}
</style>
